/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 (function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $('.eq_height').matchHeight({});
        $(window).scroll(function() {
          var scrollPos = $(window).scrollTop();
          if (scrollPos > 10) {
            $('#header_nav').addClass('scrolled');
          } else {
            $('#header_nav').removeClass('scrolled');
          }
        });
        var vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', vh + 'px');
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {

        $.stellar({
          horizontalScrolling: false,
          verticalOffset: 40
        });

        $('#donate_home_btns label.btn').click(function(event) {
          /* Act on the event */

          var dollar_amount = $(this).find('.donate_home_btn').attr('id').replace('option_', '$');
       //   console.log($(this).find('.donate_home_btn'));
         // console.log(dollar_amount);
         $('#donate_amount').val(dollar_amount);
       });

        function customAmountToggle() {
          var $radioAmount = $('.home__donate-form-item .radio-btn');
          var $customAmount = $('.home__donate-custom-amount');
          $customAmount.on('click', function(){
            $radioAmount.each(function(index, value){
              $(this).prop('checked', false);
            });
          });
        }
        customAmountToggle();
        
        var $sticky = $('.home__programs-content--dekstop');
        var $stickyrStopper = $('.stop-sticky');

        if ($sticky.offset()) { 
          var generalSidebarHeight = $sticky.innerHeight();
          var stickyTop = $sticky.offset().top;
          var stickOffset = 100;
          var stickyStopperPosition = $stickyrStopper.offset().top;
          var stopPoint = stickyStopperPosition - generalSidebarHeight - stickOffset;
          var diff = stopPoint + stickOffset;


          $(window).scroll(function(){ 
            var windowTop = $(window).scrollTop();
            if (stopPoint < windowTop) {
              $sticky.css({ position: 'absolute', top: diff });
            } else if (stickyTop < windowTop+stickOffset) {
                $sticky.css({ position: 'fixed', top: stickOffset });
            } else {
                $sticky.css({position: 'absolute', top: 'initial'});
            }
          });
        }

        function preloadImages() {
          var $iconModule = $('.home__support-donate-wrapper div');
          $iconModule.each(function(index, value){
            var img_src = $(this).attr('data-preload');
            var img = new Image();
            img.src = img_src;
          });
        }
        preloadImages();

        function initIconLoop() {
            var $iconModules = $('.home__support-donate-wrapper'),
                moduleActive = 'home__support-donate-wrapper--active',
                delay = 12500;
            function loopThroughIcons() { 
                $iconModules.first().addClass(moduleActive);
                function iconRotate() {
                    var $current = $('.home__support-donate-wrapper--active');
                    $next = $current.next();
                    if ($current.next().length !== 0) {
                        $current.removeClass('home__support-donate-wrapper--active');
                        $next.addClass(moduleActive);
                    }
                    if ($current.next().length !== 0) {
                        setTimeout( iconRotate, delay );
                    }
                }
                setTimeout( iconRotate, delay );
            }
            function hoverPlay() {
              $iconModules.mouseenter(function() {
                $iconModules.removeClass('home__support-donate-wrapper--active');
                $(this).addClass(moduleActive);
              });
            }
            hoverPlay();
            function _loopActive() {
                return $iconModules.hasClass(moduleActive);
            }
            function startLoopThrough() {
                if ($iconModules.length) {
                  loopThroughIcons();
                }
            }
            startLoopThrough();
        }
        initIconLoop();
        


        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'media_room': {
      init: function() {


        $('.media_room_menu > ul li a').click(function(e) {
          e.preventDefault();
          var href = $(this).attr('href');
          var hash = href.substr(href.indexOf("#"));
            if (hash) {
              $('.hamburger').trigger('click');
              $('.nav-tabs a[href="'+hash+'"]').tab('show');
              $('html,body').scrollTop(0);
            }
        });

       var hash = document.location.hash;

       if (hash) {
        $('.nav-tabs a[href="'+hash+'"]').tab('show');
        $('html,body').scrollTop(0);
      }

    }
  }
};

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
